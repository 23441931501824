<template>
  <b-container class="mt-5">
    <b-card
      class="text-center"
      style="min-height: 560px;"
    >
      <!-- <h3 class="mb-2">
        از ثبت نام شما ممنونیم!
      </h3> -->
      <p
        v-if="!end"
        style="font-weight: bold;"
      >
        جهت ارائه مشاوره بهتر درباره بازارهای مالی و معاملات آنلاین لطفا به سوال زیر پاسخ دهید:
      </p>
      <br>
      <!-- {{ userAnswers }} -->
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <div v-show="!change">
          <h4 v-if="!end">
            {{ currentQuestion.question }}
          </h4>
          <span v-if="!end">{{ currentQuestion.optional ? '( اختیاری )':'' }}</span>

          <b-form-group
            v-if="!end"
            label-cols="12"
            label-size="lg"
          >
            <div v-if="!currentQuestion.answerType">
              <b-form-radio-group
                v-model="selectedAnswer"
                class="d-flex flex-column"
                @change="handleNextStep"
              >
                <b-form-radio
                  v-for="options in currentQuestion.options"
                  :key="options.value"
                  :value="options.value"
                  class="my-2"
                >
                  <span style="font-weight: bold;">
                    {{ options.text }}
                  </span>
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <div v-else-if="currentQuestion.answerType === 'text'">
              <b-form-input
                v-model="selectedAnswer"
                type="text"
                placeholder=""
                class="w-md-50 mx-auto"
              />
              <b-button
                class="w-100 w-md-50 mx-auto mt-1"
                variant="success"
                :disabled="!selectedAnswer?( !currentQuestion.optional) : false "
                @click="handleNextStep"
              >
                بعدی
              </b-button>
            </div>
          </b-form-group>
          <div
            v-if="end"
            cols="4"
          >
            <div>
              <p style="font-weight: bold;">
                {{ getOffer().text }}
              </p>
              <p>
                {{ getOffer().description }}
              </p>
              <p> {{ getOffer().subText }} </p>
            </div>
            <b-row>
              <b-col
                v-for="link in getOffer().links"
                :key="link.title"
                cols="12"
                md="4"
                class="p-1"
              >
                <b-card style="position: relative;box-shadow: 0 0 3px 0 !important;height: 100%;">
                  <h4>{{ link.title }}</h4>
                  <p>{{ link.description }}</p>
                  <b-button
                    style="position: absolute;bottom: 10px;left: 50%; transform: translate(-50%, 0);width: 95%;"
                    variant="success"
                    @click="openLink(link.link)"
                  >مشاهده</b-button>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div
            v-if="end"
            class="mt-2"
          >
            <validation-observer
              #default="{invalid}"
            >
              <p>شماره موبایل مورد نظر خود را برای دریافت <strong>کد تایید</strong> وارد کنید:</p>
              <b-form @submit.prevent="sendOtpCode()">
                <b-form-group
                  label="شماره موبایل"
                  label-for="mobile-number"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('confirm_withdraw_code')"
                    vid="mobile"
                    rules="required"
                  >
                    <b-form-input
                      id="mobile-number"
                      v-model="mobileNumber"
                      type="tel"
                      placeholder="0916XXXXXXX"
                      required
                      class="w-md-50 w-lg-25 mx-auto"
                    />
                  </validation-provider>
                </b-form-group>
                <div
                  class="mx-auto "
                  style="width: 300px;"
                >
                  <b-form-group class="mt-1 ">
                    <vue-recaptcha
                      ref="recaptcha"
                      :sitekey="sitekey"
                      :load-recaptcha-script="true"
                      :theme="$store.state.appConfig.layout.skin == 'dark' ? 'dark' : ''"
                      @expired="onCaptchaExpired"
                      @verify="onVerify"
                    />
                  </b-form-group>
                </div>
                <b-button
                  type="submit"
                  variant="success"
                  :disabled="!recaptcha || !mobileNumber || isVerified"
                >
                  <div>
                    <span v-if="!sendOtpLoading">ارسال کد تایید</span>
                    <b-spinner
                      v-else
                      small
                      size="10"
                      variant="light"
                      type="grow"
                    />
                  </div>
                </b-button>

              </b-form>
            </validation-observer>
            <b-modal
              v-model="otpModal"
              :title="$t('confirm_withdraw')"
              centered
              size="md"
              hide-footer
              no-close-on-backdrop
            >
              <validation-observer
                #default="{invalid}"
              >
                <b-row>
                  <b-col>
                    <b-form-group>
                      <div
                        v-if="mobileNumber"
                        class="my-1"
                      >
                        ایمیل مورد نظر خود را وارد کنید. یک <strong>لینک فعال سازی</strong> برای شما ارسال خواهد شد.
                      </div>
                      <div class="d-flex justify-content-between">
                        <label for="code">{{ $t('email') }}</label>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('email')"
                        vid="email"
                        rules="required"
                      >
                        <b-input-group>
                          <b-form-input
                            id="email"
                            v-model="email"
                            name="email"
                            placeholder="example@gmail.com"
                            class="mb-1"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-if="mobileNumber"
                      class="my-1"
                    >
                      لطفا کد ارسال شده به <strong>موبایل</strong> را وارد کنید
                    </div>
                    <b-form
                      class="auth-login-form mb-2 "
                    >

                      <b-form-group>
                        <div class="d-flex justify-content-between">
                          <label for="code">کد</label>
                        </div>
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('code')"
                          vid="code"
                          rules="required"
                        >
                          <b-input-group>
                            <b-form-input
                              id="code"
                              v-model="confirmCode"
                              name="code"
                              placeholder="0000"
                              class="mb-1"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-button
                      block
                      size="md"
                      variant="warning"
                      :disabled="verifyCounter !== 0"
                      @click="sendOtpCode(true)"
                    >
                      <div v-if="!resendOtpLoading">
                        <span>
                          {{ $t('resend') }}
                        </span>
                        <span v-if="verifyCounter !== 0">( {{ convertSecondsToMinutes(verifyCounter) }} )</span>
                      </div>
                      <b-spinner
                        v-else
                        small
                        size="10"
                        variant="light"
                        type="grow"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      block
                      size="md"
                      :disabled="invalid || confirmCode.length < 4"
                      variant="success"
                      @click="verify"
                    >
                      <span v-if="!verifyLoading">
                        {{ $t('widrawModal.confirm') }}
                      </span>
                      <b-spinner
                        v-else
                        small
                        size="10"
                        variant="light"
                        type="grow"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-modal>
          </div>
        </div>
      </transition>
    </b-card>
  </b-container>
</template>

<script>
import { required } from '@validations'
import VueRecaptcha from 'vue-recaptcha'
import axiosIns from '@/libs/axios'
import { convertSecondsToMinutes } from '@/utils'

export default {
  components: { VueRecaptcha },
  data() {
    return {
      convertSecondsToMinutes,
      required,
      currentQuestion: {},
      selectedAnswer: null,
      userAnswers: [],
      otpModal: false,
      mobileNumber: this.$route.query.mobile || '',
      otpNumber: '',
      email: this.$route.query.email || '',
      isNextQuestionAvailable: true,
      end: false,
      change: false,
      verifyCounter: 0,
      resendOtpLoading: false,
      sendOtpLoading: false,
      verifyLoading: false,
      confirmCode: '',
      recaptcha: '',
      isVerified: false,
      countdownInterval: null,
      questions: [
        {
          question: 'میزان آشنایی شما با بازار فارکس؟',
          options: [
            { value: 'no_experience', text: 'هیچ آشنایی ندارم' },
            { value: 'learning', text: 'در حال آموزش و یادگیری هستم' },
            { value: 'professional_trader', text: 'کاملا آشنا هستم و سابقه ترید دارم' },
            // { value: 'familiar_no_trade', text: 'آشنا هستم ولی تمایلی به ترید کردن ندارم' },
          ],
          condition: question => question.length === 0, /* && (question[0].answer.value === 'trade_income' || question[0].answer.value === 'partner_income'), */
        },
        {
          question: 'از چه روشی در بازار های مالی میخواهید سود کسب کنید؟',
          options: [
            { value: 'trade_income', text: 'درآمد دلاری از طریق ترید کردن.' },
            { value: 'partner_income', text: 'درآمد دلاری از طریق مشارکت با تریدر های حرفه ای.' },
            { value: 'dollar_investment', text: 'درآمد دلاری از طریق سرمایه‌گذاری.' },
            { value: 'rial_investment', text: 'درآمد ریالی از طریق سرمایه‌گذاری.' },
          ],
          condition: question => question.length === 1,
        },
        {
          question: 'میزان سرمایه‌گذاری به ریال؟',
          options: [
            { value: '1-10_million', text: '10,000,000 - 100,000,000 ریال' },
            { value: '11-50_million', text: '110,000,000 - 500,000,000 ریال' },
            { value: '50-100_million', text: '510,000,000 -1000,000,000  ریال' },
            { value: '101-500_million', text: '1,010,000,000 - 5,000,000,000 ریال' },
            { value: 'above_500_million', text: 'بالای 5,000,000,000 ریال' },
          ],
          condition: question => question.length === 2 && question[1].answer.value === 'rial_investment',
        },
        {
          question: 'میزان سرمایه‌گذاری به دلار؟',
          options: [

            { value: '1-10_dollar', text: '10 - 100 دلار' },
            { value: '11-50_dollar', text: '101 - 500 دلار' },
            { value: '50-100_dollar', text: '501 - 1000  دلار' },
            { value: '101-500_dollar', text: '1001 - 5000 دلار' },
            { value: 'above_500_dollar', text: 'بالای 5000 دلار' },
          ],
          condition: question => question.length === 2 && question[1].answer.value === 'dollar_investment',
        },
        {
          question: 'میزان توقع شما از میزان درامد در ماه؟',
          options: [

            { value: '0_5_percent', text: '0-5 درصد' },
            { value: '6_10_percent', text: '6-10 درصد' },
            { value: '11_30_percent', text: '11-30 درصد' },
            { value: '31_50_percent', text: '31-50 درصد' },
            { value: '51_80_percent', text: '51-80 درصد' },
            { value: '81_100_percent', text: '81 - 100 درصد' },
            { value: 'above_100_percent', text: '100 درصد به بالا' },
          ],
          condition: question => question.length === 2 && (question[1].answer.value === 'no_experience' || question[1].answer.value === 'learning'),
        },
        {
          question: 'سابقه فعالیت در بازار فارکس؟',
          options: [

            { value: '0_2_years', text: '0-2 سال' },
            { value: '2_5_years', text: '2-5 سال' },
            { value: 'above_5_years', text: '5 سال به بالا' },
          ],
          condition: question => question.length === 2 && (question[1].answer.value === 'trade_income' || question[1].answer.value === 'partner_income')
           && question[0].answer.value === 'professional_trader',
        },
        {
          question: 'آیا در بروکر دیگری فعالیت میکنید؟',
          options: [
            { value: 'no', text: 'خیر' },
            { value: 'yes', text: 'بله' },
          ],
          condition: question => question.length === 3 && question[0].answer.value === 'professional_trader',
        },
        {
          answerType: 'text',
          optional: true,
          question: 'لطفا نام بروکر را وارد کنید.',
          options: [
            { value: 'no', text: 'خیر' },
            { value: 'yes', text: 'بssله' },
          ],
          condition: question => question.length === 4 && question[0].answer.value === 'professional_trader' && question[3].answer.value === 'yes',
        },
      ],
      offers:
        {
          no_experience: {
            text: 'بروکر PCM با سابقه ی 12 سال حضور در بازارهای مالی خدمات متنوعی را در اختیار مشتریان عزیز قرار میدهد.',
            description: 'متخصصین و مربیان ما آماده هستند تا در این مسیر شما را یاری کنند. لطفا شماره موبایل خود را ثبت کنید تا با شما تماس بگیریم.',
            subText: 'همچنین می‌توانید بخشی از خدمات ما را از لینک های زیر بررسی کنید',
            links: [
              {
                title: 'دانشگاه تریدری',
                description: 'دانشگاه تریدری محیطی مناسب برای یادگیری بازارهای مالی و فارکس است که با دوره های متنوع و مناسب با هدف شما آماده شده است.',
                link: 'https://pcmfa.academy/',
              },
              {
                title: 'امکان همکاری با تریدرهای حرفه‌ای و کسب درآمد دلاری.',
                description: 'مجموعه PCM این امکان را برای مشتریان عزیز فراهم کرده که با استفاده کابین مشتریان بتوانند با تریدر های موفق همکاری کرده و بعد از توافق با آنها درآمد دلاری کسب کنند. برای اطلاعات بیشتر منتظر تماس متخصصین ما باشید.',
                link: 'https://pcmgate.co',
              },
              {
                title: 'کابین مشتریان',
                description: 'این کابین محیط مناسبی برای فعالیت مشتریان در بازار های مالی است که با امکانات و ابزارهای لازم برای فعالیت در بازار فارکس، آماده شده است.',
                link: 'https://pcmgate.co',
              },
            ],
          },
          learning: {
            text: 'بروکر PCM با سابقه ی 12 سال حضور در بازارهای مالی خدمات متنوعی را در اختیار مشتریان عزیز قرار میدهد. دانشگاه تریدری یکی از خدمات مجموعه است که به شما کمک می کند تا تریدر موفق تری در بازارهای مالی باشید.',
            description: 'متخصصین و مربیان ما آماده هستند تا به شما در یادگیری و پیشرفت در بازارهای مالی کمک کنند. لطفا شماره موبایل خود را وارد کنید تا با شما تماس بگیریم.',
            subText: '',
            links: [
              {
                title: 'دانشگاه تریدری',
                description: 'دانشگاه تریدری محیطی مناسب برای یادگیری بازارهای مالی و فارکس است که با دوره های متنوع و مناسب با هدف شما آماده شده است.',
                link: 'https://pcmfa.academy/',
              },
              {
                title: 'امکان همکاری با تریدر های حرفه ای و کسب درآمد دلاری.',
                description: 'مجموعه PCM این امکان را برای مشتریان عزیز فراهم کرده که با استفاده کابین مشتریان بتوانند با تریدر های موفق همکاری کرده و بعد از توافق با آنها درآمد دلاری کسب کنند. برای اطلاعات بیشتر منتظر تماس متخصصین ما باشید.',
                link: 'https://pcmgate.co',
              },
              {
                title: 'کابین مشتریان',
                description: 'این کابین محیط مناسبی برای فعالیت مشتریان در بازار های مالی است که با امکانات و ابزارهای لازم برای فعالیت در بازار فارکس، آماده شده است.',
                link: 'https://pcmgate.co',
              },
            ],
          },
          professional_trader: {
            text: 'بروکر PCM با سابقه ی 12 سال حضور در بازارهای مالی خدمات متنوعی را در اختیار تریدر های حرفه ای قرار میدهد. کابین مشتریان با خدمات متنوع مکان مناسبی برای تریدر های حرفه ای می باشد.',
            description: 'مجموعه ما آفر ویژه ای برای تریدر های حرفه ای در نظر دارد که توسط متخصصین ما به شما معرفی خواهد شد. لطفا شماره موبایل خود را ثبت کنید تا با شما تماس بگیریم.',
            subText: 'همچنین می‌توانیدید بخشی از خدمات ما را از لینک های زیر بررسی کنید',
            links: [
              {
                title: 'کابین مشتریان',
                description: 'این کابین محیط مناسبی برای فعالیت مشتریان در بازار های مالی است که با امکانات و ابزارهای لازم برای فعالیت در بازار فارکس، آماده شده است.',
                link: 'https://pcmgate.co',
              },
              {
                title: 'امکان جذب سرمایه برای تریدر های حرفه ای',
                description: 'مجموعه ما امکان همکاری مشتریان با تریدر های حرفه ای را فراهم نموده که امکان درامد بیشتر را برای تریدر های محترم فراهم می کند. برای اطلاعات بیشتر منتظر تماس متخصصین ما باشید.',
                link: 'https://pcmgate.co',
              },
              {
                title: 'لیست حساب‌های فارکس',
                description: 'ما در مجموعه PCM حساب های متنوعی را ارائه می‌کنیم که متناسب با نیاز های مختلف شما می باشد.',
                link: 'https://pcmfa.co/types-of-accounts',
              },
            ],
          },
          familiar_no_trade: {
            text: 'در صورتی که تمایل ندارید خودتان به صورت مستقیم ترید کنید مجموعه PCM امکانی را در اختیار شما قرار می دهد که با همکاری تریدرها حرفه ای و توافق با آن‌ها بتوانید از سرمایه خود بهترین استفاده را نمایید.',
            description: 'برای اطلاعات بیشتر لطفا شماره موبایل خود را ثبت کنید تا متخصصین ما با شما تماس بگیرند.',
            subText: 'همچنین می‌توانیدید بخشی از خدمات ما را از لینک های زیر بررسی کنید',
            links: [
              {
                title: 'امکان همکاری با تریدر های حرفه ای و کسب درآمد دلاری.',
                description: 'مجموعه PCM این امکان را برای مشتریان عزیز فراهم کرده که با استفاده کابین مشتریان بتوانند با تریدر های موفق همکاری کرده و بعد از توافق با آنها درآمد دلاری کسب کنند. برای اطلاعات بیشتر منتظر تماس متخصصین ما باشید.',
                link: 'https://pcmgate.co',
              },
              {
                title: 'کابین مشتریان',
                description: 'این کابین محیط مناسبی برای فعالیت مشتریان در بازار های مالی است که با امکانات و ابزارهای لازم برای فعالیت در بازار فارکس، آماده شده است.',
                link: 'https://pcmgate.co',
              },
            ],
          },
          rial_investment: {
            text: 'مجموعه PCM امکانی را برای مشتریان محترم فراهم نموده که با سرمایه‌گذاری در بازار های مالی می‌توانیدید از سرمایه خود بهترین استفاده را نمایید. به طور میانگین میزان سود سرمایه‌گذاری در PCM حدود 35 درصد است.',
            description: 'برای اطلاعات بیشتر لطفا شماره موبایل خود را ثبت کنید تا متخصصین ما با شما تماس بگیرند.',
            subText: 'همچنین می‌توانیدید بخشی از خدمات ما را از لینک های زیر بررسی کنید.',
            links: [
              {
                title: 'کابین مشتریان',
                description: 'این کابین محیط مناسبی برای فعالیت مشتریان در بازار های مالی است که با امکانات و ابزارهای لازم برای فعالیت در بازار فارکس، آماده شده است.',
                link: 'https://pcmgate.co',
              },
              {
                title: 'امکان جذب سرمایه برای تریدر های حرفه ای',
                description: 'مجموعه ما امکان همکاری مشتریان با تریدر های حرفه ای را فراهم نموده که امکان درامد بیشتر را برای تریدر های محترم فراهم می کند. برای اطلاعات بیشتر منتظر تماس متخصصین ما باشید.',
                link: 'https://pcmgate.co',
              },
            ],
          },
          dollar_investment: {
            text: 'مجموعه PCM امکانی را برای مشتریان محترم فراهم نموده که با سرمایه‌گذاری در بازار های مالی می‌توانیدید از سرمایه خود بهترین استفاده را نمایید.',
            description: 'برای اطلاعات بیشتر لطفا شماره موبایل خود را ثبت کنید تا متخصصین ما با شما تماس بگیرند.',
            subText: 'همچنین می‌توانیدید بخشی از خدمات ما را از لینک های زیر بررسی کنید.',
            links: [
              {
                title: 'کابین مشتریان',
                description: 'این کابین محیط مناسبی برای فعالیت مشتریان در بازار های مالی است که با امکانات و ابزارهای لازم برای فعالیت در بازار فارکس، آماده شده است.',
                link: 'https://pcmgate.co',
              },
              {
                title: 'امکان جذب سرمایه برای تریدر های حرفه ای',
                description: 'مجموعه ما امکان همکاری مشتریان با تریدر های حرفه ای را فراهم نموده که امکان درامد بیشتر را برای تریدر های محترم فراهم می کند. برای اطلاعات بیشتر منتظر تماس متخصصین ما باشید.',
                link: 'https://pcmgate.co',
              },
            ],
          },
        },
    }
  },
  computed: {
    sitekey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
  },
  watch: {
    userAnswers: {
      handler() {
        this.selectedAnswer = null
        this.isNextQuestionAvailable = false
        this.questions.forEach(question => {
          if (question.condition && question.condition(this.userAnswers/* [this.userAnswers.length === 0 ? 0 : this.userAnswers.length - 1] */)) {
            this.currentQuestion = question
            this.isNextQuestionAvailable = true
          }
        })
        if (!this.isNextQuestionAvailable) {
          this.end = true
        }
      },
      deep: true,
    },
  },
  mounted() {
    const [q] = this.questions
    this.currentQuestion = q
  },
  methods: {
    getOffer() {
      return this.offers[this.userAnswers[this.userAnswers[0].answer.value === 'trade_income' || this.userAnswers[0].answer.value === 'partner_income' ? 1 : 0].answer.value]
    },
    handleNextStep() {
      this.change = true
      setTimeout(() => {
        this.change = false
        if (this.selectedAnswer || this.currentQuestion.optional) {
          this.userAnswers.push({
            question: this.currentQuestion.question,
            answer: this.currentQuestion.answerType === 'text'
              ? { value: this.selectedAnswer, text: this.selectedAnswer }
              : this.currentQuestion.options.find(option => option.value === this.selectedAnswer),
          })
        }
      }, 500)
    },
    countDownTimer() {
      if (!this.countdownInterval) {
        this.countdownInterval = setInterval(() => {
          if (this.verifyCounter !== 0) {
            this.verifyCounter -= 1
          }
        }, 1000)
      }
    },
    sendOtpCode(resend = false) {
      if (resend) {
        this.resendOtpLoading = true
      } else {
        this.sendOtpLoading = true
      }
      axiosIns.post('campaign/send-otp', { uuid: this.$route.query.uuid, mobile: this.mobileNumber.replace(/^0/, ''), mobile_country_code: '98' }).then(res => {
        this.otpModal = true
        this.verifyCounter = res.data.results.expiration_time * 60
        this.countDownTimer()
        this.$swal({
          title: 'کد تایید ارسال شد.',
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).catch(error => {
        if (error.status === 428) {
          this.otpModal = true
          this.verifyCounter = error.data.results.time
          this.countDownTimer()
        }
      }).finally(() => {
        this.sendOtpLoading = false
        this.resendOtpLoading = false
      })
    },
    verify() {
      this.verifyLoading = true
      axiosIns.post('quick-register', {
        uuid: this.$route.query.uuid,
        mobile: this.mobileNumber.replace(/^0/, ''),
        questions: JSON.stringify(this.userAnswers),
        email: this.email,
        code: this.confirmCode,
        recaptcha: this.recaptcha,
      }).then(() => {
        this.$swal({
          title: 'شماره شما ثبت شد، یک لینک تایید و رمز عبور کابین مشتریان برای شما ارسال شد. لطفا منتظر تماس متخصصین ما باشید.',
          icon: 'success',
          timer: 6000,
          showConfirmButton: false,
        })
        this.otpModal = false
        this.isVerified = true
      }).finally(() => {
        this.verifyLoading = false
      })
    },
    onVerify(response) {
      this.recaptcha = response
    },
    async onCaptchaExpired() {
      this.$refs.recaptcha.reset()
      this.recaptcha = null
    },
    openLink(link) {
      if (this.isVerified) {
        window.open(link, '_blank')
      } else {
        this.$swal({
          title: 'لطفا ابتدا شماره موبایل خود را از انتهای صفحه تایید کنید.',
          icon: 'error',
          showConfirmButton: false,

        })
      }
    },
  },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
